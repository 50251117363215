import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import SocialBar from "../../components/SocialBar";
import {useSocials} from "../../context/SocialContext";

const Sidebar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 60px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    z-index: 1000;

    @media (max-width: 1365px) and (orientation: portrait) {
        width: 75%;
        left: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '-75%')};
        background: rgba(125, 0, 8, 0.9);
        z-index: 2;
    }

    @media (min-width: 768px) and (max-width: 1365px) and (orientation: landscape) {
        left: 0;
    }

    /* Nuova media query */
    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 75%;
        left: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '-75%')};
        background: rgba(125, 0, 8, 0.9);
        z-index: 2;
    }
`;

const SidebarItem = styled(Link)`
    margin: 20px 0;
    cursor: pointer;
    font-size: 0.7em;
    color: ${(props) => props.theme.colors.textLight};
    text-decoration: none;

    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);

    &:hover {
        color: ${(props) => props.theme.colors.highlight};
    }

    @media (max-width: 1365px) and (orientation: portrait) {
        writing-mode: horizontal-tb;
        transform: rotate(0);
        font-size: 2rem;
    }

    /* Nuova media query */
    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        writing-mode: horizontal-tb;
        transform: rotate(0);
        font-size: 2rem;
    }
`;

const MobileSocialBar = styled.div`
    display: none;

    @media (max-width: 1365px) and (orientation: portrait) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;


export default function VerticalSidebar({isSidebarOpen, toggleSidebar}) {
  const {socials, loading} = useSocials();

  if (loading) {
    return <></>;
  }

  return (
    <Sidebar isSidebarOpen={isSidebarOpen}>
      <SidebarItem to="/#home" onClick={toggleSidebar}>Home</SidebarItem>
      <SidebarItem to="/#cocktails" onClick={toggleSidebar}>Cocktails</SidebarItem>
      <SidebarItem to="/#about" onClick={toggleSidebar}>About Us</SidebarItem>
      <SidebarItem to="/#contact" onClick={toggleSidebar}>Contact</SidebarItem>
      <MobileSocialBar id={"mobileSocialBar"}>
        <SocialBar socials={socials}/>
      </MobileSocialBar>
    </Sidebar>
  );
}
