import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ThemeProviderComponent from "./ThemeProviderComponent";
import VerticalSidebar from "./component/VerticalSidebar";
import { FaBars } from 'react-icons/fa';
import { SocialsProvider } from "../context/SocialContext";
import { getMaggiorenne } from "../api/Cookies";
import EntryPage from "../components/EntryPage";
import LoadingSpinner from "../components/LoadingSpinner";
import Footer from "../components/Footer";

const Container = styled.div`
    max-width: 100vw;
    margin: 0 auto;
    background-color: ${(props) => props.theme.colors.backgroundColor};
    box-sizing: border-box;
    color: #FFF6E0;
    font-size: calc(10px + 2vmin);
    display: flex;
`;

const Content = styled.div`
    flex-grow: 1;
    margin-left: ${({ isSidebarOpen, isMobileView }) =>
            isSidebarOpen && !isMobileView ? '60px' : '0'};

    @media (max-width: 1365px) and (orientation: portrait) {
        margin-left: 0;
    }

    @media (min-width: 1365px) and (orientation: landscape) {
        margin-left: 0;
    }

    @media (min-width: 768px) and (max-width: 1365px) and (orientation: landscape) {
        margin-left: 0;
    }
`;

const MobileMenuButton = styled.button`
    position: fixed;
    top: 60px;
    left: -15px;
    background: none;
    border: none;
    color: #FFF6E0;
    font-size: 2.5rem;
    z-index: 1000;
    display: flex;
    align-items: center;

    @media (max-width: 1365px) and (orientation: portrait) {
        display: flex;
    }

    @media (min-width: 1366px) and (orientation: landscape) {
        display: none;
    }

    @media (min-width: 768px) and (max-width: 1365px) and (orientation: landscape) {
        display: none;
    }
`;

export default function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCookiePresent, setIsCookiePresent] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const checkMobileView = () => {
      const isMobile =
        window.matchMedia("(max-width: 1365px)").matches &&
        window.matchMedia("(orientation: portrait)").matches;
      setIsMobileView(isMobile);
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  useEffect(() => {
    getMaggiorenne()
      .then((data) => {
        if (data === null) {
          setIsCookiePresent(false);
        } else if (data.value === "1") {
          setIsCookiePresent(true);
        }
      })
      .catch(() => {
        setIsCookiePresent(false); // Fallback in caso di errore
      });
  }, []);

  if (isCookiePresent === null) {
    return (
      <ThemeProviderComponent>
        <LoadingSpinner />
      </ThemeProviderComponent>
    );
  }

  if (isCookiePresent === false) {
    return (
      <ThemeProviderComponent>
        <EntryPage />
      </ThemeProviderComponent>
    );
  }

  return (
    <ThemeProviderComponent>
      <SocialsProvider>
        <Container>
          {isCookiePresent && (
            <>
              <MobileMenuButton onClick={toggleSidebar}>
                <FaBars />
              </MobileMenuButton>
            </>
          )}

          {isCookiePresent && (
            <VerticalSidebar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          )}

          <Content isSidebarOpen={isSidebarOpen} isMobileView={isMobileView}>
            {children}
            <Footer />
          </Content>
        </Container>
      </SocialsProvider>
    </ThemeProviderComponent>
  );
}
