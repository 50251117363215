import React from 'react';
import styled from 'styled-components';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const SidebarContainer = styled.div`
    width: 100%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1365px) and (orientation: portrait){
        align-items: center;
    }
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const MenuItem = styled.li`
  margin-right: 10px;
`;

const StyledLink = styled.a`
  text-decoration: none;
    color: #FFFFFF;
  display: flex;
  align-items: center;
`;

const iconMap = {
  YouTube: YouTubeIcon,
  Instagram: InstagramIcon,
  Facebook: FacebookIcon,
  LinkedIn: LinkedInIcon
};

function SocialBar({ socials }) {

  return (
    <SidebarContainer id={"sidebarContainer"}>
      <MenuList id={"sidebarMenuList"}>
        {socials.map((thing, index) => {
          const social = JSON.parse(thing);
          const IconComponent = iconMap[social.nome];
          return (
            <MenuItem key={index}>
              <StyledLink href={social.link} target="_blank" rel="noopener noreferrer">
                {IconComponent ? <IconComponent sx={{ fontSize: 48 }} /> : null}
              </StyledLink>
            </MenuItem>
          );
        })}
      </MenuList>
    </SidebarContainer>
  );
}

export default SocialBar;
