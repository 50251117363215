import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter, RouterProvider,
} from "react-router-dom";
import Layout from './themes/Layout';
import Root from './routes/root';
import Cocktail from './routes/cocktail';
import RouteErrorPage from './errorPages/routeErrorPage';
import SidebarLayout from "./themes/SidebarLayout";
import Cocktails from "./routes/coktails";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Root />
      </Layout>
    ),
    errorElement: <RouteErrorPage />,
  },
  /*{
    path: "cocktail/:cocktailId",
    element: (
      <SidebarLayout>
        <Cocktail />
      </SidebarLayout>
    ),
  },*/
  {
    path: "cocktail/:cocktailId",
    element: (
      <Layout>
        <Cocktail />
      </Layout>
    ),
  },
  {
    path: "cocktails",
    element: (
      <Layout>
        <Cocktails />
      </Layout>
    ),
  },
  {
    path: ":section",
    element: (
      <Layout>
        <Root />
      </Layout>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
