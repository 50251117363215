import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: ${({ imagePosition }) => (imagePosition === 'left' ? 'row-reverse' : 'row')};
    align-items: center;

    /* Media query per dispositivi mobili e iPad in modalità verticale */
    @media (max-width: 1365px) and (orientation: portrait) {
        flex-direction: column; /* Imposta la direzione del layout a colonna */
    }

    /* Media query per max-width: 1000px, landscape, touch-only */
    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        flex-direction: column; /* Stile simile a dispositivi mobili */
    }
`;

const TextColumn = styled.div`
    flex: 1;
    font-size: 1.5rem;
    padding: 1rem;

    /* Media query per ridurre il font-size su dispositivi mobili e iPad in modalità verticale */
    @media (max-width: 1365px) and (orientation: portrait) {
        font-size: 1rem; /* Riduce la dimensione del font a 1rem */
    }

    /* Media query per max-width: 1000px, landscape, touch-only */
    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        font-size: 1rem; /* Stesso stile del mobile portrait */
    }
`;

const ImageColumn = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 95%; /* Imposta la larghezza al 95% della colonna */
        height: auto; /* Mantiene l'altezza proporzionale */
        object-fit: cover; /* Adatta l'immagine per riempire l'area */
        border-radius: 8px; /* Opzionale, per rendere gli angoli arrotondati */
    }

    /* Media query per max-width: 1000px, landscape, touch-only */
    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        img {
            width: 80%; /* Regola la larghezza per adattarsi meglio */
        }
    }
`;

export default function ImageAndText({ content, imagePosition }) {
  return (
    <Container imagePosition={imagePosition}>
      <TextColumn>{content.text}</TextColumn>
      <ImageColumn>
        <img src={content.image} alt="Content visual" />
      </ImageColumn>
    </Container>
  );
}
