import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Title } from "../../themes/styled";
import CardH from "../Card";

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';

const HypeCocktailsContainer = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 80px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: 1365px) and (orientation: landscape) {
        padding: 50px;
    }

    @media (max-width: 1365px) and (orientation: portrait),
    (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        padding: 20px;
    }
`;

const Text = styled.p`
    width: 100%;
    text-align: justify;
    font-size: 1.5rem;

    @media (max-width: 1365px) {
        font-size: 1rem;
    }

    @media (max-width: 1365px) and (orientation: portrait),
    (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        font-size: 1rem;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 32px;

    @media (max-width: 1365px) {
        justify-content: center;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        justify-content: center;
    }
`;

const Button = styled(Link)`
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border: none;
    padding: 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    width: 15%;
    text-decoration: none;
    text-align: center;

    &:hover {
        opacity: 0.9;
    }

    @media (max-width: 1365px) {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 0;
    width: 100%;

    @media (max-width: 768px) {
        justify-content: center;
        text-align: center;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        justify-content: center;
        text-align: center;
    }
`;

const TwoColumnLayout = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin-top: 32px;

    @media (max-width: 1365px) and (orientation: portrait) {
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 1366px) {
        gap: 20px;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        flex-direction: column;
        align-items: center;
    }
`;

const Column = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }

    @media (max-width: 1365px) and (orientation: landscape) {
        width: 50%;
        &:last-child {
            width: 50%;
        }
    }

    @media (min-width: 1366px) {
        width: 40%;
        &:last-child {
            width: 60%;
        }
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

const CarouselContainer = styled.div`
    width: 100%;
    max-width: 70vw;
    margin: 20px auto;
    overflow: hidden;
`;

const CardWrapper = styled.div`
    padding: 10px;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        padding: 5px;
        max-width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    @media (max-width: 480px) {
        padding: 2px;
        max-width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        padding: 5px;
        margin: 0 auto;
    }
`;

const StyledCard = styled(CardH)`
    max-width: 50%;
    margin: 0 auto;
`;

export default function HypeCocktails({ text, cardData }) {
  const parseCocktails = (cocktailsJSON) => {
    return cocktailsJSON;
  };

  const parsedCardData = cardData.map((card) => parseCocktails(card));

  const settings = {
    dots: true,
    infinite: true,
    speed: 350,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [{
      breakpoint: 1365,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '20%',
      }
    }]
  };

  return (
    <HypeCocktailsContainer>
      <Title id={"title"}>COCKTAILS</Title>
      <TwoColumnLayout>
        <Column>
          <TextWrapper id={"textWrapper"}>
            <Text>{text}</Text>
          </TextWrapper>
        </Column>
        <Column>
          <CarouselContainer>
            <Slider {...settings}>
              {parsedCardData.slice(0, 3).map((card) => (
                <CardWrapper key={card.idCocktail} id={"cardWrapper"}>
                  <StyledCard
                    id={"card"}
                    index={card.idCocktail}
                    title={card.nomeCocktail}
                    image={card.urlImgCocktailMobile}
                  />
                </CardWrapper>
              ))}
            </Slider>
          </CarouselContainer>
          <ButtonWrapper>
            <Button to="/cocktails">Altri</Button>
          </ButtonWrapper>
        </Column>
      </TwoColumnLayout>
    </HypeCocktailsContainer>
  );
}
