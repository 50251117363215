import {handleRequest} from "./APIUtils";

export async function getCocktail(id) {
  try {
    return await handleRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/?action=cocktail&id=${id}`)
  } catch (error) {
    throw error;
  }
}

export async function getAllCocktailCards() {
  try {
    return await handleRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/?action=getVisualizedCard`)
  } catch (error) {
    throw error;
  }
}