import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Title} from '../../themes/styled';
import DoubleColumnComponent from '../../themes/DoubleColumnComponent';
import {newMessage} from "../../api/Contacts";

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';


const ContactDiv = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 80px;

    @media (max-width: 768px) {
        padding: 5px 20px;
        padding-bottom: 50px;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        padding: 10px 40px;
    }
`;

const TextContainer = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        padding: 0;
        margin-bottom: 25px;
        width: 100%;
    }

    @media (min-width: 769px) and (max-width: 1366px) {
        order: ${props => props.mobileorderright};
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 100%;
        margin-bottom: 20px;
    }
`;

const ContactText = styled.p`
    font-size: 1.5rem;

    @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        font-size: 1.2rem;
        text-align: center;
    }
`;

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 100%;
        align-items: center;
    }
`;

const FormRow = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        flex-direction: column;
        align-items: center;
    }
`;

const Input = styled.input`
    flex: 1;
    padding: 10px;
    margin-left: 10px;
    font-size: 1rem;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    border-radius: 10px;
    box-sizing: border-box;

    &:first-child {
        margin-left: 0;
    }

    @media (max-width: 768px) {
        width: 80%;
        margin-left: 0;
        margin-top: 10px;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 90%;
        margin-left: 0;
        margin-top: 10px;
    }
`;

const FullWidthInput = styled.input`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 0;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 90%;
    }
`;

const StyledTextArea = styled.textarea`
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-size: 1rem;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    color: black;
    border: none;
    border-bottom: 2px solid red;
    background: white;
    outline: none;
    resize: none;
    overflow: hidden;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        width: 90%;
    }
`;

function TextArea({value, placeholder, onChange}) {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (<StyledTextArea
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    ref={textAreaRef}
    onInput={() => {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }}
  />);
}

const CheckboxContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: start;
    margin-top: 10px;
    justify-content: flex-start;

    @media (max-width: 768px) {
        justify-content: center;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        justify-content: center;
    }
`;

const SubmitButton = styled.button`
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
    width: 200px;
    align-self: flex-start;

    @media (max-width: 768px) {
        align-self: center;
    }

    @media (max-width: 1000px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        align-self: center;
    }

    &:hover {
        background-color: darkred;
    }
`;

const CheckboxLabel = styled.label`
    font-size: 1rem;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
`;

const Message = styled.p`
    margin-top: 10px;
    font-size: 1.2rem;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 800;
    color: ${props => props.error ? 'red' : '#FFF6E0'};
`;

export default function Contacts({content}) {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleSubmit = () => {
    const input = {
      name, surname, email, phone, company, created_at: new Date().toISOString().slice(0, 19).replace('T', ' '), // Formatta la data
      privacy_policy_accepted: privacyPolicyAccepted, customer: content.customer,  // Assumendo che `customer` venga passato tramite `content`
      subject, message
    };

    if (privacyPolicyAccepted === false) {
      setResponseMessage("È necessario accettare la privacy policy per procedere!");
    } else {
      if (name === '' || subject === '' || surname === '' || message === '' || email === '') {
        setResponseMessage("È necessario inserire nome, cognome, email, oggetto e messaggio!");
      } else {
        newMessage(input)
          .then((response) => {
            if (response.message === "Success") {
              setResponseMessage("Messaggio inviato con successo!");
              setIsError(false);
            } else {
              setResponseMessage("Errore durante l'invio del messaggio. Riprova più tardi.");
              setIsError(true);
            }
          })
          .catch((error) => {
            setResponseMessage("Errore durante l'invio del messaggio. Riprova più tardi.");
            setIsError(true);
          })
      }
    }
  }

  return (<ContactDiv>
    <Title>CONTACT US</Title>
    <DoubleColumnComponent
      leftContent={<TextContainer>
        <ContactText>{content.text}</ContactText>
      </TextContainer>}
      rightContent={<FormContainer>
        <FormRow>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="text"
            id="surname"
            name="surname"
            placeholder="Cognome"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Numero di Telefono"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <FullWidthInput
            type="text"
            id="company"
            name="company"
            placeholder="Azienda"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <FullWidthInput
            type="text"
            id="subject"
            name="subject"
            placeholder="Oggetto"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <TextArea
            value={message}
            placeholder="Scrivi il tuo messaggio"
            onChange={(e) => setMessage(e.target.value)}
          />
        </FormRow>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="privacyPolicy"
            name="privacyPolicy"
            checked={privacyPolicyAccepted}
            onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
          />
          <CheckboxLabel htmlFor="privacyPolicy">
            Ho letto e accetto la privacy policy del sito web
          </CheckboxLabel>
        </CheckboxContainer>
        <SubmitButton type="submit" onClick={handleSubmit}>Invia</SubmitButton>
        {responseMessage && (<Message error={isError}>{responseMessage}</Message>)}
      </FormContainer>}
      leftColumnWidth="50"
      mobileOrder="leftFirst"
      tabletAsMobile={true}
    />
  </ContactDiv>);
}
