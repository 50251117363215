import React from 'react';
import styled from 'styled-components';

/*const CardLink = styled.a`
    text-decoration: none;
    color: inherit;
`;*/

const CardLink = styled.a`
    text-decoration: none;
    color: inherit;
    display: flex;               // Aggiunto per rendere CardLink un contenitore flessibile
    justify-content: center;     // Centra il contenuto orizzontalmente
    align-items: center;         // Centra il contenuto verticalmente (se necessario)
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    aspect-ratio: 9 / 16;
    min-height: 300px;

    @media (max-width: 768px) {
        aspect-ratio: 9 / 16;
        min-height: 250px;
        max-width: 250px; // Per rendere la card simile a quella della grid su mobile
    }
`;

const ImageContainer = styled.div`
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 10%;
    margin-bottom: 0;
`;

const Image = styled.img`
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 8px;
`;

const TitleContainer = styled.div`
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    flex-grow: 1;
`;

const Title = styled.h2`
    font-size: 3rem;
    margin: 0;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 1366px) and (orientation: landscape) {
        font-size: 2vh;
    }

    @media (max-width: 1365px) and (orientation: portrait) {
        font-size: 2vh;
    }
`;

const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: 150%;
`;

const CardContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 1365px) and (orientation: portrait) {
        justify-content: center;  // Centra verticalmente
        align-items: center;      // Centra orizzontalmente
    }
`;

export default function CardH({index, title, image}) {
  return (<CardLink href={`/cocktail/${index}`}>
    <CardWrapper id={"cardWrapper"}>
      <CardContentWrapper id={"cardContentWrapper"}>
        <CardContainer id={"cardContainer"}>
          <ImageContainer id={"imgContainer"}>
            <Image src={image} alt={title}/>
          </ImageContainer>
          <TitleContainer id={"title"}>
            <Title>{title}</Title>
          </TitleContainer>
        </CardContainer>
      </CardContentWrapper>
    </CardWrapper>
  </CardLink>);
}
