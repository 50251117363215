import {handlePostRequest, handleRequest} from "./APIUtils";

const apiUrl = process.env.REACT_APP_API_URL;

export async function setCookie(name, value) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/cookies/`, {
      name: name, value: value, action: "cookies"
    });
  } catch (error) {
    throw error;
  }

}

export async function getMaggiorenne() {
  try {
    const url = `${apiUrl}/cookies/?action=cookies&name=maggiorenne`;
    return await handleRequest(url);
  } catch (error) {
    throw error;
  }
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  console.log(parts);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

