import React, { useState } from 'react';
import styled from 'styled-components';
import {setCookie} from '../api/Cookies';

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';

const ApprovalContainer = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: right;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 80px;

    @media (max-width: 1366px) {
        padding: 0 40px;
    }

    @media (max-width: 768px) {
        padding: 0 20px;
    }
`;

const CenteredDiv = styled.div`
    width: 40%;
    height: 35%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;

    @media (max-width: 1366px) {
        height: 45%;
        width: 60%;
    }

    @media (max-width: 768px) {
        height: 70%;
        width: 80%;
    }
`;

const MainText = styled.h1`
    color: #FFF6E0;
    font-size: 2.5rem;
    text-align: center;
    padding: 20px;

    @media (max-width: 1366px) {
        font-size: 2.3rem;
        padding: 18px;
    }

    @media (max-width: 768px) {
        font-size: 2rem;
        padding: 15px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 0 20px;

    @media (max-width: 1366px) {
        width: 100%;
        padding: 0 10px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
`;

const StyledButton = styled.button`
    background-color: #A52A2A;
    color: #FFF6E0;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50px;  /* Impostato a 50px per bordi completamente arrotondati */
    width: 30%;
    flex: 1;
    margin: 0 10px;

    &:hover {
        background-color: darkred;
    }

    @media (max-width: 1000px) {
        width: 40%;
        margin: 0 5px;
    }

    @media (max-width: 768px) {
        width: 75%;
        margin: 10px 0;
    }
`;

const NotAllowedText = styled.p`
    color: white;
    font-size: 1.5rem;
    text-align: center;
    padding: 20px;

    @media (max-width: 1000px) {
        font-size: 1.3rem;
        padding: 18px;
    }

    @media (max-width: 768px) {
        font-size: 1.2rem;
        padding: 15px;
    }
`;

export default function EntryPage() {
  const [isNotAllowed, setIsNotAllowed] = useState(false);

  const handleSetCookie = async () => {
    const result = await setCookie("maggiorenne", true);

    if (result.message === "Cookie set") {
      window.location.reload();
    }
  };

  const handleNotAllowed = () => {
    setIsNotAllowed(true);
  };

  return (
    <ApprovalContainer>
      <CenteredDiv>
        {isNotAllowed ? (
          <NotAllowedText>Non puoi visitare questo sito</NotAllowedText>
        ) : (
          <>
            <MainText>HAI L'ETA' LEGALE PER BERE?</MainText>
            <ButtonContainer>
              <StyledButton onClick={handleSetCookie}>Sì</StyledButton>
              <StyledButton onClick={handleNotAllowed}>No</StyledButton>
            </ButtonContainer>
          </>
        )}
      </CenteredDiv>
    </ApprovalContainer>
  );
}
