const apiUrl = process.env.REACT_APP_API_URL;

export async function handleRequest(url) {
  try {
    const response = await fetch(url, {
      method: 'GET', headers: {
        'Content-Type': 'application/json'
      }, credentials: 'include'
    });

    if (!response.ok) {
      const responseBody = await response.json();
      switch (response.status) {
        case 400:
          throw new Error('Bad Request: ' + responseBody.message);
        case 404:
          throw new Error('Not Found: ' + responseBody.message);
        case 405:
          throw new Error('Method Not Allowed: ' + responseBody.message);
        case 500:
          throw new Error('Internal Server Error: ' + responseBody.message);
        default:
          throw new Error(`Unexpected error occurred. Status: ${response.status}, Message: ${responseBody.message || 'No message'}`);
      }
    }

    const responseData = await response.json();
    return responseData.data;

  } catch (error) {
    throw error;
  }
}

export async function handlePostRequest(url, bodyData) {
  const formData = new URLSearchParams();
  for (const key in bodyData) {
    formData.append(key, JSON.stringify(bodyData[key]));
  }

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  if (!response.ok) {
    throw new Error('Network response was not ok ' + response.statusText);
  }

  /*const data = await response.text();
  console.log(data);
  return data;*/

  const data = await response.json();
  console.log(data);
  return (data).data;
}


export async function fetchSocials() {
  try {
    const url = `${apiUrl}/?action=social`;
    return await handleRequest(url);
  } catch (error) {
    throw error;
  }
}
