import React from 'react';
import styled from 'styled-components';
import { Title } from '../../themes/styled';
import ImageAndText from "../../themes/component/ImageAndText";

const AboutContainer = styled.div`
    text-align: left;
    padding: 50px 80px;

    @media (max-width: 1366px) and (orientation: portrait) {
        padding: 5px 20px;
        text-align: center;
        padding-bottom: 50px;
    }
`;

const ImageAndTextWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;

      @media (max-width: 1366px) and (orientation: landscape) {
          margin-bottom: 0;
      }
  }
`;

export default function About({ content }) {

  return (
    <AboutContainer>
      <Title>ABOUT US</Title>
      {content.map((item, index) => (
        <ImageAndTextWrapper key={index}>
          <ImageAndText
            content={item}
            imagePosition={index % 2 === 0 ? 'left' : 'right'}
          />
        </ImageAndTextWrapper>
      ))}
    </AboutContainer>
  );
}
