import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 2rem;
    color: #FFF6E0;
    margin-top: 0;
    
    @media (max-width: 768px) {
        text-align: center;
        padding-top: 50px;
    }
`;
