import React, { useEffect } from 'react';
import styled from 'styled-components';

const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 30px;
    padding-bottom: 30px;

    background-color: #131313;
`;

const LogoImage = styled.img`
    width: 5%;
    height: auto;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 20%;
    }
`;

const InfoText = styled.p`
    font-size: 0.7rem;
    color: #FFF6E0;
    text-align: center;
    margin-bottom: 5px;
`;

const LinksContainer = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 10px;
`;

const PolicyLink = styled.a`
    font-size: 0.7rem;
    color: #FFF6E0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default function Footer() {
  useEffect(() => {
    // Carica lo script di iubenda solo una volta quando il componente è montato
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.iubenda.com/iubenda.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup: rimuovi lo script quando il componente viene smontato
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <CenteredDiv id={"footerdiv"}>
      <LogoImage
        src='https://liquorebonito.it/logo512.png'
        alt='Logo Liquore Bonito'
      />
      <InfoText>
        Via di Tiglio, 1697 - 55100 Lucca – Tel +39 0583 980394 – Fax +39 0583
        980323
      </InfoText>
      <InfoText>
        P.iva 02391070469 – info@caffebonito.it – rncaffe@pec.it
      </InfoText>
      <InfoText>
        CAPITALE SOCIALE RN CAFFÈ SRL 50.000€ – REA LUCCA 221881
      </InfoText>

      <LinksContainer>
        <PolicyLink
          href="https://www.iubenda.com/termini-e-condizioni/67814348"
          className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe"
          title="Termini e Condizioni"
        >
          Termini e Condizioni
        </PolicyLink>
        <PolicyLink
          href="https://www.iubenda.com/privacy-policy/67814348/cookie-policy"
          className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe"
          title="Cookie Policy"
        >
          Cookie Policy
        </PolicyLink>
        <PolicyLink
          href="https://www.iubenda.com/privacy-policy/67814348"
          className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe"
          title="Privacy Policy"
        >
          Privacy Policy
        </PolicyLink>
      </LinksContainer>
    </CenteredDiv>
  );
}
