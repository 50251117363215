import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FaArrowDown} from 'react-icons/fa';
import styled from "styled-components";
import ThemeProviderComponent from "../themes/ThemeProviderComponent";
import {getCocktail} from '../api/Cocktail';
import LoadingSpinner from '../components/LoadingSpinner';
import CocktailErrorPage from "../errorPages/cocktailErrorPage";
import SocialBar from "../components/SocialBar";

const IngredientParagraph = styled.p`
    margin-top: 5px;
    color: #FFF6E0;
`;

const IngredientWithSymbol = ({quantity, symbol, name}) => (
  <IngredientParagraph>{name} {quantity} {symbol}</IngredientParagraph>
);

const IngredientWithoutSymbol = ({quantity, unit, name}) => (
  <IngredientParagraph>{quantity} {unit} {name}</IngredientParagraph>
);

const getPhotoContainer = (cocktail) => styled.div`
    background-image: url(${cocktail.urlImgCocktail});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 80px;
    text-align: right;
    position: relative;

    @media (max-width: 1365px) and (orientation: portrait) {
        height: 100vh;
        width: 100vw;
        background-image: url(${cocktail.urlImgCocktailMobile});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        padding: 0 0;
    }
`;

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';

const ContentContainer = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    overflow: hidden;

    width: 100%;
`;

const Content = styled.div`
    width: 65%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        margin: 0;
    }
`;

const LeftColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #FFF6E0;

    @media (max-width: 768px) {
        padding-left: 20px;
    }
`;

const RightColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #FFF6E0;

    @media (max-width: 768px) {
        padding-left: 20px;
    }
`;

const SubPhotoContainer = styled.div`
    width: 45%;
    padding: 30px;
    flex-direction: column;
    text-align: left;

    @media (max-width: 1365px) and (orientation: portrait) {
        width: 100%;
        padding: 20px;
    }
`;

const Title = styled.h1`

    color: #FFF6E0;
    margin-top: 35px;

    @media (max-width: 1365px) and (orientation: portrait) {
        position: absolute;
        right: 20px;
        color: white;
        font-size: 10vw;
    }
`;

const DesktopText = styled.div`
    color: #FFF6E0;

    @media (max-width: 1366px) and (orientation: portrait) {
        display: none;
    }
`;

const TextContainer = styled.div`
    color: #FFF6E0;

    @media (max-width: 1365px) and (orientation: portrait) {
        padding: 20px;
    }
`;

const Text = styled.p`
    font-size: 1.5rem;
    text-align: justify;
    color: #FFF6E0;

    @media (max-width: 1365px) and (orientation: portrait) {
        margin-top: 20px;
        font-size: 1rem;
    }
`;

const SocialBarContainer = styled.div`
    color: #FFF6E0;

    @media (max-width: 1365px) and (orientation: portrait) {
        display: none;
    }
`;

const ProductionProcessContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #FFF6E0;
`;

const MobileText = styled.div`
    @media (min-width: 1366px) or (orientation: landscape) {
        display: none;
    }
`;

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #FFF;
    background-color: transparent;
    color: #FFF;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
`;

const ArrowCircle = () => {
  const handleScroll = () => {
    const element = document.getElementById("process");
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <Circle onClick={handleScroll}>
      <FaArrowDown size={24}/>
    </Circle>
  );
};

export default function Cocktail() {
  const {cocktailId} = useParams();
  const [cocktail, setCocktail] = useState(null);
  const [socials, setSocials] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getCocktail(cocktailId)
      .then(data => {
        const dataParsed = JSON.parse(data);
        const socials = dataParsed.social.map((social) => JSON.stringify({
          nome: social.social_platform, link: social.url_social
        }));
        setSocials(socials);
        setCocktail(dataParsed);

        console.log(dataParsed);
      })
      .catch(e => {
        setError(true);
        console.log(e);
      });
  }, [cocktailId]);

  const parseIngredienti = (list) => {
    if (Array.isArray(list)) {
      return (
        <>
          {list.map((item) => item.symbol ? (
            <IngredientWithSymbol key={item.id_ingredient} quantity={item.quantity} symbol={item.symbol}
                                  name={item.nome_ingredient}/>
          ) : (
            <IngredientWithoutSymbol key={item.id_ingredient} quantity={item.quantity} unit={item.unit}
                                     name={item.nome_ingredient}/>
          ))}
        </>
      );
    } else {
      console.error('I dati degli ingredienti non sono un array.');
      return <p>Errore nel formato degli ingredienti.</p>;
    }
  };

  const renderProcessoProduttivo = (processo) => (
    <ProductionProcessContainer>
      {processo.map((step) => (
        <p key={step.passo}>{step.descrizione}</p>
      ))}
    </ProductionProcessContainer>
  );

  if (error) return (<CocktailErrorPage/>);

  const PhotoContainer = (cocktail || socials) ? getPhotoContainer(cocktail) : (<></>);

  return (cocktail || socials) ? (
    <ThemeProviderComponent>
      <PhotoContainer>
        <SubPhotoContainer>
          <Title id={"subPhoto"}>{cocktail.nomeCocktail}</Title>
          <DesktopText>
            <TextContainer>
              <Text id={"desktop"}>
                {cocktail.descrizioneCocktail}
              </Text>
            </TextContainer>
          </DesktopText>
          <SocialBarContainer>
            <SocialBar socials={socials}/>
          </SocialBarContainer>
        </SubPhotoContainer>

        <ArrowCircle/>
      </PhotoContainer>

      <MobileText>
        <TextContainer>
          <Text>
            {cocktail.descrizioneCocktail}
          </Text>
        </TextContainer>
      </MobileText>

      <ContentContainer>
        <Content id="process">
          <LeftColumn>
            <h2>Ingredienti</h2>
            {parseIngredienti(cocktail.ingredienti)}
          </LeftColumn>
          <RightColumn>
            <h2>Processo Produttivo</h2>
            {renderProcessoProduttivo(cocktail.processoProduttivo)}
          </RightColumn>
        </Content>
      </ContentContainer>
    </ThemeProviderComponent>
  ) : <LoadingSpinner/>;
}
