import {handlePostRequest} from "./APIUtils";

export async function newMessage(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/messages/`, {
      data: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}