import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ThemeProviderComponent from "./ThemeProviderComponent";
import VerticalSidebar from "./component/VerticalSidebar";
import {SocialsProvider} from "../context/SocialContext";
import {FaBars} from "react-icons/fa";
import {getMaggiorenne} from "../api/Cookies";
import Footer from "../components/Footer";

const backgroundImageUrl = process.env.REACT_APP_backgroundPhoto;

const LayoutContainer = styled.div`
    background-size: cover;
    color: ${(props) => props.theme.colors.textRed};
    background-attachment: fixed;
    max-width: 100vw;
    margin: 0 auto;
    background-image: url(${backgroundImageUrl});
    box-sizing: border-box;
    font-size: calc(10px + 2vmin);
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;


const AppContainer = styled.div`
    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const SideBarAppHeader = styled.header`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
`;

const Content = styled.div`
    flex: 1;
    /*margin-top: 30px;
    margin-left: 60px;*/
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 768px) {
        margin-left: 0;
    }
`;

const MobileMenuButton = styled.button`
    position: fixed;
    top: 60px;
    left: -15px;
    background: none;
    border: none;
    color: #FFF6E0;
    font-size: 2.5rem;
    z-index: 1000;
    display: flex;
    align-items: center;

    @media (max-width: 1365px) and (orientation: portrait) {
        display: flex;
    }

    @media (min-width: 1366px) {
        display: none;
    }
`;

const FooterDiv = styled.div`
    background-color: #131313,
`;

export default function SidebarLayout({children}) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isCookiePresent, setIsCookiePresent] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    getMaggiorenne()
      .then(data => {

        if (data.value === '1') setIsCookiePresent(true); else setIsCookiePresent(false);
      })
  }, []);

  return (<ThemeProviderComponent>
      <SocialsProvider>
        <LayoutContainer>
          {isCookiePresent && (<>
              <MobileMenuButton onClick={toggleSidebar}>
                <FaBars/>
              </MobileMenuButton>
            </>)}

          {isCookiePresent && (<VerticalSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>)}

          <Content isSidebarOpen={isSidebarOpen}>
            {children}
            <FooterDiv id={"FooterWrapper"}>
              <Footer/>
            </FooterDiv>
          </Content>
        </LayoutContainer>
      </SocialsProvider>
    </ThemeProviderComponent>);
}
