import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoadingSpinner from "../components/LoadingSpinner";
import { getAllCocktailCards } from "../api/Cocktail";
import { Title } from "../themes/styled";
import ErrorPage from "../themes/component/ErrorPage";
import CardForGrid from "../components/CardGrid";

const mapCocktailToCard = (cocktail) => ({
  title: cocktail.nomeCocktail,
  image: cocktail.urlImgCocktailMobile,
  id: cocktail.idCocktail
});

// URL dell'immagine di sfondo
const backgroundImageUrl = process.env.REACT_APP_backgroundPhoto;

// Wrapper per mantenere l'immagine di sfondo fissa
const PageWrapper = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    min-height: 100vh;
    padding: 80px;

    @media (max-width: 1365px) and (orientation: portrait) {
        background-size: contain;
        background-position: top;

        /*display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;*/
        padding: 0 0;
    }
`;

const CardGridContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const GridContainer = styled.div`
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 1365px) and (orientation: portrait) {
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        gap: 8px;
    }
`;

function CardGrid({ cocktails }) {
  console.log(cocktails);

  return (
    <CardGridContainer>
      <GridContainer>
        {cocktails.map((cocktail, index) => (
          <CardForGrid key={index} index={cocktail.id} title={cocktail.title} image={cocktail.image} />
        ))}
      </GridContainer>
    </CardGridContainer>
  );
}

export default function Cocktails() {
  const [cocktails, setCocktails] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getAllCocktailCards()
      .then(data => {
        const parsedCocktails = data.map(item => mapCocktailToCard(item));
        setCocktails(parsedCocktails);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  if (error) {
    return <ErrorPage><h1>Failed To Fetch Cocktails</h1></ErrorPage>;
  }

  if (!cocktails) {
    return <LoadingSpinner />
  }

  return (
    <PageWrapper>
      <Title>Cocktails</Title>
      <CardGrid cocktails={cocktails} />
    </PageWrapper>
  );
}
